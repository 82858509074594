.signersGroup {
  margin-bottom: 40px;
}

.signers {
  margin-bottom: 24px !important;
}

.signers button {
  padding-left: 20px;
}

.toggle {
  padding-left: 20px;
}
