@import '~@appclose/ui/src/scss/functions';

.invoice {
  margin-top: 60px;
}

.invoice > p {
  color: var(--color-info);
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}
