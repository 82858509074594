@import '~@appclose/ui/src/themes/base.module.scss';
@import '~@appclose/ui/src/animations/fadeInUp.module.scss';
@import '~@appclose/ui/src/animations/fadeOutUp.module.scss';

@import '~@appclose/ui/src/scss/mixins';

:root {
  --loader-src: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRpc3BsYXk9ImJsb2NrIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgdmlld0JveD0iMCAwIDEwMCAxMDAiPgogIDxkZWZzLz4KICA8Y2lyY2xlIGN4PSIzMCIgY3k9IjUwIiByPSIyMCIgZmlsbD0iI0ZGN0IwMCI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgYmVnaW49Ii0wLjVzIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMzA7NzA7MzAiLz4KICA8L2NpcmNsZT4KICA8Y2lyY2xlIGN4PSI3MCIgY3k9IjUwIiByPSIyMCIgZmlsbD0iIzQ1OEZFMSI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgYmVnaW49IjBzIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMzA7NzA7MzAiLz4KICA8L2NpcmNsZT4KICA8Y2lyY2xlIGN4PSIzMCIgY3k9IjUwIiByPSIyMCIgZmlsbD0iI0ZGN0IwMCI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgYmVnaW49Ii0wLjVzIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMzA7NzA7MzAiLz4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImZpbGwtb3BhY2l0eSIgY2FsY01vZGU9ImRpc2NyZXRlIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjQ5OTswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMDswOzE7MSIvPgogIDwvY2lyY2xlPgo8L3N2Zz4K');
}

:global(.notification-item) {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

:global(#root) {
  display: flex;
  flex-direction: column;
}

body {
  background-color: #f8f9fa;
}

@include mobile {
  body {
    background-color: #fff;
  }
}
