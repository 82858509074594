@import '~@appclose/ui/src/scss/functions';
@import '~@appclose/ui/src/components/Typography/scss/mixins';

.icon {
  fill: get-color('icons', 'brand');
}

.iconButton {
  background-color: get-color('background', 'primary');
  border-radius: 50%;
}

.button {
  @include text(5, 'semibold', 'tertiary');

  min-height: auto;
  padding: 2px 10px;
  opacity: 0.5;
}

.modalOverlay {
  z-index: 2;
}
