@import '~@appclose/ui/src/scss/functions';

.container {
  width: 100%;
  height: 132px;
  padding: 12px 8px;
  background-color: get-color('background', 'secondary');
  border-radius: 20px;
  cursor: pointer;
}

.iconWrapper {
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: get-color('background', 'tertiary');
  border-radius: 50%;
}
