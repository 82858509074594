@import '~@appclose/ui/src/scss/functions';
@import '~@appclose/ui/src/components/Typography/scss/mixins';

.label {
  @include caption(3, 'semibold');

  padding: 3px 8px;
  white-space: nowrap;
  border-radius: 8px;
}

.primary {
  color: get-color('status', 'text2');
  background-color: get-color('status', '2');
}

.info {
  color: get-color('status', 'text4');
  background-color: get-color('status', '4');
}

.success {
  color: get-color('status', 'text1');
  background-color: get-color('status', '1');
}

.warning {
  color: get-color('status', 'text3');
  background-color: get-color('status', '3');
}

.default {
  color: get-color('text', 'contrast');
  background-color: var(--color-default);
}

.brand {
  color: var(--color-default);
  background-color: var(--color-brand);
}

.secondary {
  color: get-color('status', 'text5');
  background-color: get-color('status', '5');
}

.meta {
  color: get-color('status', 'text6');
  background-color: get-color('status', '6');
}

.tertiary {
  color: get-color('status', 'text7');
  background-color: get-color('status', '7');
}

.white {
  color: get-color('text', 'primary');
  background-color: get-color('background', 'primary');
}
