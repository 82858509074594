@import '~@appclose/ui/src/scss/functions';

.container {
  position: absolute;
  pointer-events: none;
}

.element {
  pointer-events: none;
}

.block {
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
}

.withBackground {
  box-sizing: content-box;
  padding: 12px;
  background-color: get-color('background', 'primary');
  border-radius: 16px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
}
