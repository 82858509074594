@import '~@appclose/ui/src/components/Typography/scss/mixins';

.checkbox {
  height: 32px;
  margin-top: 12px;
}

.label {
  @include text(4, 'semibold');
}
