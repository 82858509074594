@import '../scss/functions';
@import '../components/Typography/scss/mixins';

:root {
  /** Colors **/
  --color-default: #{get-color('text', 'primary')};
  --color-primary: #{get-color('system', 'primary')};
  --color-success: #{get-color('system', 'success')};
  --color-warning: #{get-color('system', 'warning')};
  --color-info: #{get-color('system', 'gray')};
  --color-utility: #{get-color('background', 'secondary')};
  --color-brand: #{get-color('brand', 'primaryYellow')};
  --color-contrast: #{get-color('text', 'contrast')};

  /** Loader **/
  --loader-src: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRpc3BsYXk9ImJsb2NrIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgdmlld0JveD0iMCAwIDEwMCAxMDAiPgogIDxkZWZzLz4KICA8Y2lyY2xlIGN4PSIzMCIgY3k9IjUwIiByPSIyMCIgZmlsbD0iI0ZGN0IwMCI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgYmVnaW49Ii0wLjVzIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMzA7NzA7MzAiLz4KICA8L2NpcmNsZT4KICA8Y2lyY2xlIGN4PSI3MCIgY3k9IjUwIiByPSIyMCIgZmlsbD0iIzQ1OEZFMSI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgYmVnaW49IjBzIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMzA7NzA7MzAiLz4KICA8L2NpcmNsZT4KICA8Y2lyY2xlIGN4PSIzMCIgY3k9IjUwIiByPSIyMCIgZmlsbD0iI0ZGN0IwMCI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgYmVnaW49Ii0wLjVzIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMzA7NzA7MzAiLz4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImZpbGwtb3BhY2l0eSIgY2FsY01vZGU9ImRpc2NyZXRlIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjQ5OTswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMDswOzE7MSIvPgogIDwvY2lyY2xlPgo8L3N2Zz4K');
  --loader-width: 40px;
  --loader-height: var(--loader-width);

  /** Font **/
  --font-default: 'NunitoSans', sans-serif;
}

@font-face {
  font-weight: normal;
  font-family: 'NunitoSans';
  font-style: normal;
  src: url('../assets/fonts/NunitoSans/NunitoSans-Regular/NunitoSans-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: 'NunitoSans';
  font-style: normal;
  src: url('../assets/fonts/NunitoSans/NunitoSans-Regular/NunitoSans-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-family: 'NunitoSans';
  font-style: normal;
  src: url('../assets/fonts/NunitoSans/NunitoSans-SemiBold/NunitoSans-SemiBold.ttf');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: 'NunitoSans';
  font-style: normal;
  src: url('../assets/fonts/NunitoSans/NunitoSans-Bold/NunitoSans-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-weight: 900;
  font-family: 'NunitoSans';
  font-style: normal;
  src: url('../assets/fonts/NunitoSans/NunitoSans-Black/NunitoSans-Black.ttf');
  font-display: swap;
}

*,
::before,
::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  @include text(3);

  height: 100%;
  margin: 0;
  font-family: var(--font-default);
  background-color: var(--color-utility);
}

:global(#root) {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

p {
  margin: 0;
}
